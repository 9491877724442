<template>
    <b-overlay :show="loading">
        <b-row class="mb-2">
            <!-- Header -->
            <b-col cols="12" class="mb-2">
                <b-button variant="primary" v-if="allowCreate()" @click.prevent="add">Tambah</b-button>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->
        </b-row>
        <!-- Body -->
        <b-card>
            <b-row>
                <b-col cols="12">
                    <b-table :filter="filter" :per-page="perPage" :current-page="currentPage" responsive :fields="fields" :items="penjualans">
                        <template #cell(order)="{index}">
                            {{++index}}
                        </template>
                        <template #cell(total)="{item}">
                            Rp {{ formatRupiah(item.total) }}
                        </template>
                        <template #cell(status)="{item}">
                            <b-badge v-if="item.selesai == 1" variant="light-success">
                                Sudah selesai
                            </b-badge>
                            <b-badge v-else variant="light-danger">
                                Belum selesai
                            </b-badge>
                        </template>
                        <template #cell(actions)="{item}">
                            <div class="flex align-items-center justify-center">
                                <b-button
                                    size="sm"
                                    @click="$router.push(`/asset-penjualan/detail/${item.id}`)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button
                                    v-if="allowUpdate() && item.selesai == 0"
                                    size="sm"
                                    @click.prevent="edit(item)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EditIcon" />
                                </b-button>
                                <b-button
                                    v-if="allowDelete() && item.selesai == 0"
                                    size="sm"
                                    @click="remove(item)"
                                    class="mr-1"
                                    variant="outline-danger"
                                    >
                                    <feather-icon icon="TrashIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                    />
                </b-col>
            </b-row>
        </b-card>
        <!-- / -->
        <!-- Modal create -->
        <b-modal no-close-on-backdrop no-close-on-esc id="form-penjualan" centered size="sm" title="Form Penjualan Aset">
            <validation-observer ref="form-penjualan">
                <b-form-group label="Nama Penjualan">
                    <validation-provider
                        #default="{ errors }"
                        name="nama"
                        rules="required"
                    >
                        <b-form-input v-model="form.nama"></b-form-input>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Tanggal Pengadaan">
                    <validation-provider
                        #default="{ errors }"
                        name="tanggal"
                        rules="required"
                    >
                        <b-form-input type="date" v-model="form.tanggal"></b-form-input>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Keterangan">
                    <b-form-textarea v-model="form.keterangan"></b-form-textarea>
                </b-form-group>
            </validation-observer>
            <template #modal-footer>
                <b-button @click.prevent="submit" variant="primary">Simpan</b-button>
            </template>
        </b-modal>
        <!-- / -->
    </b-overlay>
</template>
<script>
import {BOverlay, BFormTextarea, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BOverlay, BFormTextarea,
        BRow, BCol, BBadge, BFormGroup, BCard, BPagination, BTable, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect,
        vSelect
    },
    data: () => ({
        required,
        loading: false,
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
            {key: 'order', label: 'No'},
            {key: 'no', label: 'No. Penjualan'},
            {key: 'nama', label: 'Nama'},
            {key: 'tanggal', label: 'Tanggal'},
            {key: 'total', label: 'Total Penjualan'},
            {key: 'status', label: 'Status'},
            {key: 'actions', label: '#'}
        ],
        penjualans: [],
        form: {
            id_gudang: null,
            nama: null,
            tanggal: null,
            keterangan: null
        },
        id: null
    }),
    methods: {
        submit() {
            this.$refs['form-penjualan'].validate().then(async success => {
                if(success) {
                    const payload = this.form
                    if(this.id) {
                        payload.id = this.id
                    }
                    if(this.myGudang) {
                        payload.id_gudang = this.myGudang.id
                    }

                    try {
                        this.loading = true
                        const penjualan = await this.$store.dispatch('asset/savePenjualan', [payload])
                        await this.getPenjualan()
                        this.loading = false
                        if(this.id) {
                            this.$bvModal.hide('form-penjualan')
                            this.resetForm()
                            this.getPenjualan()
                        }
                        else {
                            this.resetForm()
                            this.$router.push(`/asset-penjualan/detail/${penjualan.id}`)
                        }
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        edit(item) {
            this.id = item.id
            this.form.id_gudang = item.id_gudang
            this.form.tanggal = item.tanggal
            this.form.nama = item.nama
            this.form.keterangan = item.keterangan
            this.$bvModal.show('form-penjualan')
        },
        async removeRincian(penjualan) {
            if(!penjualan.rincian || penjualan.rincian.length < 1) {
                return Promise.resolve()
            }
            const payloads = penjualan.rincian.map(item => ({ id: item.id, fungsi: 2 }))
            try {
                await this.$store.dispatch('asset/savePenjualanRincian', payloads)
                return Promise.resolve()
            }
            catch(e) {
                return Promise.reject(e)
            }
        },
        remove(item)  {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data penjualan ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(async res => {
                if(res.value) {
                    const payload = {
                        fungsi: 2,
                        id: item.id
                    }

                    try {
                        this.loading = true
                        // delete rincian first
                        await this.removeRincian(item)
                        await this.$store.dispatch('asset/savePenjualan', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: 'Data penjualan berhasil dihapus'
                        })
                        this.getPenjualan()
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        resetForm() {
            this.id = null
            delete this.form.id
            this.form.id_gudang = null
            this.form.nama = null
            this.form.tanggal = this.getCurrentDate()
            this.form.keterangan = null
        },
        add() {
            this.resetForm()
            this.$bvModal.show('form-penjualan')
        },
        detail(item) {
            this.$router.push(`/pengadaan/detail/${item.id}`)
        },
        async getPenjualan() {
            this.loading = true
            const params = {}
            if(this.myGudang) params.id_gudang = this.myGudang.id
            this.penjualans = await this.$store.dispatch('asset/getPenjualan', params)
            this.loading = false
        }
    },
    created() {
        this.form.tanggal = this.getCurrentDate()
        this.getPenjualan()
    }
}
</script>